<template>
  <div class="row">
    <div class="col-xl-2 w20percent">
      <div class="card border-0 bg-secondary text-white" v-if="loading">
        <div class="card-body">
          <a-skeleton avatar active :paragraph="{ rows: 0 }" />
        </div>
      </div>
      <div class="card border-0 bg-success text-white" v-else>
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center">
            <i class="fa fa-star-o font-size-40 mr-3"></i>
            <div>
              <div class="font-size-21 font-weight-bold">{{ total }}</div>
              <div class="font-size-15">Всего</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-2 w20percent">
      <div class="card border-0 bg-secondary text-white" v-if="loading">
        <div class="card-body">
          <a-skeleton avatar active :paragraph="{ rows: 0 }" />
        </div>
      </div>
      <div class="card border-0 bg-primary text-white" v-else>
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center">
            <i class="fa fa-thumbs-o-up font-size-40 mr-3"></i>
            <div>
              <div class="font-size-21 font-weight-bold">{{ positive }}</div>
              <div class="font-size-14">Положительных</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-2 w20percent">
      <div class="card border-0 bg-secondary" v-if="loading">
        <div class="card-body">
          <a-skeleton avatar active :paragraph="{ rows: 0 }" />
        </div>
      </div>
      <div class="card border-0 bg-secondary text-white" v-else>
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center">
            <i class="fa fa-thumbs-o-down font-size-40 mr-3"></i>
            <div>
              <div class="font-size-21 font-weight-bold">{{ negative }}</div>
              <div class="font-size-14">Отрицательных</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-2 w20percent">
      <div class="card border-0 bg-secondary" v-if="loading">
        <div class="card-body">
          <a-skeleton avatar active :paragraph="{ rows: 0 }" />
        </div>
      </div>
      <div class="card border-0 bg-gray-2" v-else>
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center">
            <i class="fa fa-star-half-empty font-size-40 mr-3"></i>
            <div>
              <div class="font-size-21 font-weight-bold">{{ average }}</div>
              <div class="font-size-14">Средний рейтинг</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-2 w20percent">
      <div class="card border-0 bg-secondary text-white" v-if="loading">
        <div class="card-body">
          <a-skeleton avatar active :paragraph="{ rows: 0 }" />
        </div>
      </div>
      <div class="card border-0 bg-danger text-white" v-else>
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center">
            <i class="fa fa-exclamation font-size-40 mr-3 ml-2"></i>
            <div>
              <div class="font-size-21 font-weight-bold">{{ withoutRating }}</div>
              <div class="font-size-14">Без оценки</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VbNumberCards',
  props: {
    loading: {
      type: Boolean,
      default() {
        return false
      },
    },
    total: {
      type: Number,
      default() {
        return 0
      },
    },
    positive: {
      type: Number,
      default() {
        return 0
      },
    },
    negative: {
      type: Number,
      default() {
        return 0
      },
    },
    average: {
      type: Number,
      default() {
        return 0
      },
    },
    withoutRating: {
      type: Number,
      default() {
        return 0
      },
    },
  },
  setup(props) {
    return {
    }
  },
}
</script>

<style scoped>

.card-header, .card-footer, .card-body {
  background: transparent;
  padding: 1.33rem 1.36rem;
}
.col-xl-2.w20percent{
  flex: 0 0 20%;
  max-width: 20%;
}

</style>
