export default function OrderDetails(attrs) {
  this.guid = attrs.guid ? attrs.guid : null
  this.Guid = attrs.Guid ? attrs.Guid : null

  this.number = attrs.number ? attrs.number : null
  this.Number = attrs.Number ? attrs.Number : null

  this.open = attrs.open ? attrs.open : null
  this.Open = attrs.Open ? attrs.Open : null

  this.close = attrs.close ? attrs.close : null
  this.Close = attrs.Close ? attrs.Close : null

  this.paid = attrs.paid ? attrs.paid : null
  this.Paid = attrs.Paid ? attrs.Paid : null

  this.table = attrs.table ? attrs.table : null
  this.Table = attrs.Table ? attrs.Table : null

  this.cashdesk = attrs.cashdesk ? attrs.cashdesk : null
  this.Cashdesk = attrs.Cashdesk ? attrs.Cashdesk : null

  this.employee = attrs.employee ? attrs.employee : null
  this.Employee = attrs.Employee ? attrs.Employee : null

  this.finished = attrs.finished ? attrs.finished : null
  this.Finished = attrs.Finished ? attrs.Finished : null

  this.orderSum = attrs.orderSum ? attrs.orderSum : null
  this.OrderSum = attrs.OrderSum ? attrs.OrderSum : null

  this.discounts = attrs.discounts ? attrs.discounts : null
  this.Discounts = attrs.Discounts ? attrs.Discounts : null

  this.unpaidSum = attrs.unpaidSum ? attrs.unpaidSum : null
  this.UnpaidSum = attrs.UnpaidSum ? attrs.UnpaidSum : null

  this.guestCount = attrs.guestCount ? attrs.guestCount : null
  this.GuestCount = attrs.GuestCount ? attrs.GuestCount : null

  this.discountCount = attrs.discountCount ? attrs.discountCount : null
  this.DiscountCount = attrs.DiscountCount ? attrs.DiscountCount : null

  this.totalPieces = attrs.totalPieces ? attrs.totalPieces : null
  this.TotalPieces = attrs.TotalPieces ? attrs.TotalPieces : null

  this.latitude = attrs.latitude ? attrs.latitude : null
  this.Latitude = attrs.Latitude ? attrs.Latitude : null

  this.longitude = attrs.longitude ? attrs.longitude : null
  this.Longitude = attrs.Longitude ? attrs.Longitude : null

  this.items = attrs.items && attrs.items.length !== 0 ? attrs.items : null
  this.Items = attrs.Items && attrs.Items.length !== 0 ? attrs.Items : null
}
